import * as actionTypes from '../actions/actionTypes';

const initialState = {
  idcard: null,
  idcardImagePreviewUrl: null
};

const setIdcardImagefile = ( state, idcard ) => {
  const updatedState = {
    ...state,
    idcard: idcard.idcard,
    idcardImagePreviewUrl: idcard.idcardImagePreviewUrl
  };
  return updatedState;
};

const reducer = ( state = initialState, action ) => {
  switch( action.type ) {
    case actionTypes.SET_IDCARD_IMAGE:
      return setIdcardImagefile(state, action.idcard);
    default:
      return state;
  }
};

export default reducer;