import React, { Component } from 'react';

class Card extends Component {

  render() {
    return (
      <div className='my-4 p-8 max-w-md sm:max-w-xl rounded-sm overflow-hidden shadow-md bg-white'>
        {this.props.children}
      </div>
    );
  }

}

export default Card;