import * as actionTypes from '../actions/actionTypes';

const initialState = {
  front: null,
  frontImagePreviewUrl: null,
  left: null,
  leftImagePreviewUrl: null,
  right: null,
  rightImagePreviewUrl: null,
  below: null,
  belowImagePreviewUrl: null,
  above: null,
  aboveImagePreviewUrl: null
};

const setUserImage = (state, image) => {
  const updatedState = {
    ...state,
    [image.type]: image.data,
    [image.type + 'ImagePreviewUrl']: image.imagePreviewUrl
  };
  return updatedState;
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.SET_USER_IMAGE:
      return setUserImage(state, action.image);
    default:
      return state;
  }
};

export default reducer;