import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import MainLayout from '../../layouts/MainLayout';
import IdCardCard from '../../components/IdCardCard/IdCardCard';
import * as actions from '../../store/actions/index';

class IdCard extends Component {

  state = {
    idcard: '',
    idcardImagePreviewUrl: null
  }

  componentDidMount = () => {
    if(this.props.idcard) {
      this.setState({
        idcard: this.props.idcard,
        idcardImagePreviewUrl: this.props.idcardImagePreviewUrl
      });
    }
  }

  fileSelectedHandler = event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
        this.setState({ idcard: file, idcardImagePreviewUrl: reader.result});
        this.props.onSetIdcard({
          idcard: file,
          idcardImagePreviewUrl: reader.result
        });
    }
    reader.readAsDataURL(file)
  }

  backButtonClicked = () => {
    this.props.history.push('/');
  }

  nextButtonClicked = () => {
    this.props.history.push('/user');
  }

  render() {
    return (
      <MainLayout>
        <IdCardCard 
          idcard={this.state.idcard}
          idcardImagePreviewUrl={this.state.idcardImagePreviewUrl}
          fileSelectedHandler={this.fileSelectedHandler}
          backButtonClicked={this.backButtonClicked}
          nextButtonClicked={this.nextButtonClicked}
        ></IdCardCard>
      </MainLayout>
    );
  }

}

const mapStateToProps = state => {
  return {
    idcard: state.idcard.idcard,
    idcardImagePreviewUrl: state.idcard.idcardImagePreviewUrl
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onSetIdcard: (idcard) => dispatch(actions.setIdcard(idcard))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IdCard));