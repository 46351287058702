import React, { Component } from 'react';
import { Button, Label, Input } from 'reactstrap';
import Card from '../Card/Card';
import PolicyCard from '../PolicyCard';
import styles from './MemberCard.module.css';
import { render } from "react-dom";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class MemberCard extends Component {

  state = {
    isPasswordShown: false,
  }

  togglePasswordVisibility = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown })
  }

  changedHandler = (attr, event) => {
    this.props.input(attr, event.target.value);
  }

  render() {
    const { isPasswordShown } = this.state;
    const required = (<span className='text-red-600'>*</span>);

    return (
      <Card>
        {/* สมัครสมาชิก */}
        <div className='flex justify-center my-2'>
          <span className='font-medium text-4xl head-title'>สมัครสมาชิก</span>
        </div>
        {/* ชื่อ / นามสกุล */}
        <div className='md:flex'>
          <div className={`w-full py-2 px-1 ${styles.prefixlabel}`}>
            <Label for="prefixName" className='font-medium text-sm'>คำนำหน้า <span className={styles.thailabel}>(ภาษาไทย)</span> {required}</Label>
            <Input type="text" name="prefixName" id="prefixName" value={this.props.prefixName} onChange={(event) => this.changedHandler('prefixName', event)} />
            <span className='text-sm text-red-500'>{this.props.prefixNameError}</span>
          </div>
          <div className='w-full py-2 px-1'>
            <Label for="firstname" className='font-medium text-sm'>ชื่อ <span className={styles.thailabel}>(ภาษาไทย)</span> {required}</Label>
            <Input type="text" name="firstname" id="firstname" value={this.props.firstName} onChange={(event) => this.changedHandler('firstName', event)} />
            <span className='text-sm text-red-500'>{this.props.firstNameError}</span>
          </div>
          <div className='w-full py-2 px-1'>
            <Label for="lastname" className='font-medium text-sm'>นามสกุล <span className={styles.thailabel}>(ภาษาไทย)</span> {required}</Label>
            <Input type="text" name="lastname" id="lastname" value={this.props.lastName} onChange={(event) => this.changedHandler('lastName', event)} />
            <span className='text-sm text-red-500'>{this.props.lastNameError}</span>
          </div>
        </div>
        <div className='md:flex'>
          <div className='w-full p-2'>
            <Label for="idcardNumber" className='font-medium text-sm'>เลขบัตรประจำตัวประชาชน {required}</Label>
            <Input type="text" name="idcardNumber" id="idcardNumber" value={this.props.idcardNumber} onChange={(event) => this.changedHandler('idcardNumber', event)} />
            <span className='text-sm text-red-500'>{this.props.idcardNumberError}</span>
          </div>
        </div>
        {/* อีเมล / เบอร์โทรศัพท์ */}
        <div className='md:flex'>
          <div className='w-full md:w-1/2 p-2'>
            <Label for="email" className='font-medium text-sm'>อีเมล {required}</Label>
            <Input type="text" name="email" id="email" value={this.props.email.toLowerCase()} onChange={(event) => this.changedHandler('email', event)}/>
            <span className='text-sm text-red-500'>{this.props.emailError}</span>
          </div>
          <div className='w-full md:w-1/2 p-2'>
            <Label for="phonenumber" className='font-medium text-sm'>เบอร์โทรศัพท์ {required}</Label>
            <Input type="text" name="phonenumber" id="phonenumber" value={this.props.phoneNumber} onChange={(event) => this.changedHandler('phoneNumber', event)} />
            <span className='text-sm text-red-500'>{this.props.phoneNumberError}</span>
          </div>
        </div>
        {/* รหัสผ่าน / ยืนยันรหัสผ่าน */}
        <div className='md:flex'>
          <div className='w-full md:w-1/2 p-2'>
            <Label for="password" className='font-medium text-sm'>รหัสผ่าน {required}</Label>
            <Input type={(isPasswordShown) ? "text" : "password"} name="password" id="password" value={this.props.password} onChange={(event) => this.changedHandler('password', event)} />
            <span className='text-sm text-red-500'>{this.props.passwordError}</span>
          </div>
          <div className='w-full md:w-1/2 p-2'>
            <Label for="passwordConfirm" className='font-medium text-sm'>ยืนยันรหัสผ่าน {required}</Label>
            <Input type={(isPasswordShown) ? "text" : "password"} name="passwordConfirm" id="passwordConfirm" value={this.props.passwordConfirm} onChange={(event) => this.changedHandler('passwordConfirm', event)} />
            <span className='text-sm text-red-500'>{this.props.passwordConfirmError}</span>
          </div>
        </div>
        <div style={{ width: "100%" }}><button onClick={this.togglePasswordVisibility} style={{ position: "relative", left: "94%" }}><FontAwesomeIcon icon={faEye} /></button></div>
        {/* ข้อกำหนดในการตั้งรหัสผ่าน */}
        <div className='px-2 py-3'>
          <div>
            <Label className='font-medium text-sm'>ข้อกำหนดในการตั้งรหัสผ่าน</Label>
          </div>
          <div>
            <span className='text-sm'>
              <span className='mr-2'>● </span>อย่างน้อย 8 ตัวอักษร
            </span><br />
            <span className='text-sm'>
              <span className='mr-2'>● </span>มีตัวเลขอย่างน้อย 1 ตัว
            </span><br />
            <span className='text-sm'>
              <span className='mr-2'>● </span>มีตัวอักษรภาษาอังกฤษทั้งพิมพ์ใหญ่และพิมพ์เล็กอย่างน้อย 1 ตัว
            </span>
          </div>
        </div>
        {/* ปุ่มสมัครสมาชิก */}
        <div>
          <div className='flex justify-center px-2 mt-4'>
            <Button style={{backgroundColor: "#ffb007", border: "2px solid #ffc507"}} className='w-1/3 btn-main' onClick={this.props.next}>ถัดไป</Button>
          </div>
        </div>
        <PolicyCard/>
      </Card >
    );
  }

}

export default MemberCard;