import * as actionTypes from './actionTypes';

export const setMember = ( member ) => {
  return {
    type: actionTypes.SET_MEMBER,
    member: member
  };
};

export const clearMember = () => {
  return {
    type: actionTypes.CLEAR_MEMBER,
    member: null
  };
};