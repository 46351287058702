import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../store/actions/index';
import MainLayout from '../../layouts/MainLayout';
import UserCard from '../../components/UserCard/UserCard';
import WebcamAlertModal from '../../components/WebcamAlertModal';

class User extends Component {

  state = {
    front: null,
    frontImagePreviewUrl: null,
    left: null,
    leftImagePreviewUrl: null,
    right: null,
    rightImagePreviewUrl: null,
    below: null,
    belowImagePreviewUrl: null,
    above: null,
    aboveImagePreviewUrl: null
  }

  checkBrowser() {
    //mobile 
    if (navigator.userAgent.indexOf("iPhone") > -1 || navigator.userAgent.indexOf("iPad") > -1|| navigator.userAgent.indexOf("Android") > -1) {
      if(navigator.userAgent.indexOf("Line") > -1) {
        //line for ios | android
        return "unknown";
      } else if (navigator.userAgent.indexOf("Instagram") > -1) {
        //instagram for ios | android
        return "unknown";
      } else if (navigator.userAgent.indexOf("FBAN") > -1) {
        //facebook app for ios
        return "unknown";
      } else if (navigator.userAgent.indexOf("FB_IAB") > -1) {
        //facebook app for android
        return "unknown";
      }
    //desktop
    } else if (navigator.userAgent.indexOf("Firefox") > -1) {
      return "Firefox";
    } else if (navigator.userAgent.indexOf("SamsungBrowser") > -1) {
      //SamsungBrowser
      return "unknown";
    } else if (navigator.userAgent.indexOf("Opera") > -1 || navigator.userAgent.indexOf('OPR') > -1) {
      //Opera
      return "unknown";
    } else if (navigator.userAgent.indexOf("Edg") > -1) {
      return "Microsoft Edge";
    } else if (navigator.userAgent.indexOf("Chrome") > -1) {
      return "Google Chrome";
    } else if (navigator.userAgent.indexOf("Safari") > -1) {
      return "Safari";
    } else {
      return "unknown"
    }
  }

  componentDidMount() {
    let types = ['front', 'left', 'right', 'below', 'above'];
    for(let type of types) {
      this.setState({
        [type]: this.props[type],
        [type + 'ImagePreviewUrl']: this.props[type + 'ImagePreviewUrl']
      });
    }
  }

  fileSelectedHandler = (event, type) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
        this.setState({ [type]: file, [type + 'ImagePreviewUrl']: reader.result});
        this.props.onSetUser({ type: type, data: file, imagePreviewUrl: reader.result });
    }
    reader.readAsDataURL(file);
  }

  backButtonClicked = () => {
    this.props.history.push('/idcard');
  }

  nextButtonClicked = () => {
    this.props.history.push('/confirm');
  }

  render() {
    return (
      <MainLayout>
        { this.checkBrowser() == "unknown" ? <WebcamAlertModal/> : null }
        <UserCard
          frontImagePreviewUrl={this.state.frontImagePreviewUrl}
          leftImagePreviewUrl={this.state.leftImagePreviewUrl}
          rightImagePreviewUrl={this.state.rightImagePreviewUrl}
          belowImagePreviewUrl={this.state.belowImagePreviewUrl}
          aboveImagePreviewUrl={this.state.aboveImagePreviewUrl}
          fileSelectedHandler={this.fileSelectedHandler}
          backButtonClicked={this.backButtonClicked}
          nextButtonClicked={this.nextButtonClicked}
        ></UserCard>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  let types = ['front', 'left', 'right', 'below', 'above'];
  let propsState = {};
  for(let type of types) {
    propsState[type] = state.user[type];
    propsState[type + 'ImagePreviewUrl'] = state.user[type + 'ImagePreviewUrl'];
  }
  return {...propsState}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetUser: (image) => dispatch(actions.setUser(image))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(User));