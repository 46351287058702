import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Webcam from "webcam-easy";

import * as actions from "../../store/actions/index";
import MainLayout from "../../layouts/MainLayout";
import Card from "../../components/Card/Card";
import FrontDefaultImage from "../../images/front-example.png";
import LeftDefaultImage from "../../images/left-example.png";
import RightDefaultImage from "../../images/right-example.png";
import UpDefaultImage from "../../images/up-example.png";
import DownDefaultImage from "../../images/down-example.png";

class UserUpload extends Component {
  state = {
    webcamElement: null,
    canvasElement: null,
    webcam: null,
    canvasCtx: null,
  };

  types = ["front", "left", "right", "below", "above"];
  typeLabels = ["หน้าตรง", "หันซ้าย", "หันขวา", "มุมก้ม", "มุมเงย"];
  defaultTypeImages = [
    FrontDefaultImage,
    LeftDefaultImage,
    RightDefaultImage,
    DownDefaultImage,
    UpDefaultImage,
  ];

  componentDidMount() {
    const webcamElement = document.getElementById("webcam");
    const canvasElement = document.getElementById("canvas");
    const webcam = new Webcam(webcamElement, "environment");
    navigator.getMedia =
      navigator.getUserMedia || // use the proper vendor prefix
      navigator.mediaDevices.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    var constraints = { video: true };
    navigator.mediaDevices.getUserMedia(constraints).then(function () {
      //webcam is available
      //turn on take picture button
      document.getElementById("take-picture").style.visibility = "visible";
    });

    const canvasCtx = canvasElement.getContext("2d");
    this.setState({
      webcamElement,
      canvasElement,
      webcam,
      canvasCtx,
    });
    webcam.flip();
    webcam
      .start()
      .then((result) => {})
      .catch((err) => console.log(err));
  }

  takePhoto = () => {
    this.state.canvasCtx.translate(640, 0);
    this.state.canvasCtx.scale(-1, 1);
    this.state.canvasCtx.drawImage(this.state.webcamElement, 0, 0, 640, 480);
    let dataUrl = this.state.canvasElement.toDataURL("image/jpeg");
    let blobBin = atob(dataUrl.split(",")[1]);
    let array = [];
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    var blob = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
    var file = new File([blob], `face-${this.props.match.params.type}.jpg`);
    this.state.canvasCtx.scale(1, -1);
    this.state.webcam.stop();
    this.fileSelectedHandler(file, this.props.match.params.type);
  };

  fileSelectedHandler = (file, type) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.props.onSetUser({
        type: type,
        data: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
    setTimeout(() => this.props.history.goBack(), 500);
  };

  render() {
    return (
      <MainLayout>
        <Card>
          <div className="overflow-y-auto">
            <div className={"flex"}>
              <div className="w-3/4">
                <p className="text-2xl font-bold mt-3">ถ่ายภาพใบหน้าของคุณ</p>
                <p>
                  หัน
                  <u>
                    {
                      this.typeLabels[
                        this.types.indexOf(this.props.match.params.type)
                      ]
                    }
                  </u>
                  ตามภาพตัวอย่าง
                </p>
              </div>
              <div className="w-1/4 flex justify-end">
                <img
                  src={
                    this.defaultTypeImages[
                      this.types.indexOf(this.props.match.params.type)
                    ]
                  }
                  style={{ width: "100px", height: "120px" }}
                  alt=""
                ></img>
              </div>
            </div>
            <div className="flex justify-center border border-black p-1 mt-3">
              <video
                id="webcam"
                autoPlay
                playsInline
                className="flex object-cover items-center"
                width="400"
                height="480"
              ></video>
              <canvas
                id="canvas"
                className="d-none"
                width="640"
                height="480"
              ></canvas>
            </div>
            <div className="text-center my-3">
              <Button
                id="take-picture"
                color={window.profileConfig.themeColor}
                onClick={() => this.takePhoto()}
              >
                ถ่ายภาพ
              </Button>
            </div>
          </div>
        </Card>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  let types = ["front", "left", "right", "below", "above"];
  let propsState = {};
  for (let type of types) {
    propsState[type] = state.user[type];
    propsState[type + "ImagePreviewUrl"] = state.user[type + "ImagePreviewUrl"];
  }
  return { ...propsState };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetUser: (image) => dispatch(actions.setUser(image)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserUpload));
