import React, { Component } from "react";
import { Button } from "reactstrap";

import Card from "../Card/Card";
import ImageView from "../ImageView/ImageView";
import FrontDefaultImage from "../../images/front-example.png";
import LeftDefaultImage from "../../images/left-example.png";
import RightDefaultImage from "../../images/right-example.png";
import UpDefaultImage from "../../images/up-example.png";
import DownDefaultImage from "../../images/down-example.png";

class UserCard extends Component {
  types = ["front", "left", "right", "below", "above"];
  typeLabels = [
    "ภาพหน้าตรง",
    "ภาพหันซ้าย",
    "ภาพหันขวา",
    "ภาพมุมก้ม",
    "ภาพมุมเงย",
  ];
  defaultTypeImages = [
    FrontDefaultImage,
    LeftDefaultImage,
    RightDefaultImage,
    DownDefaultImage,
    UpDefaultImage,
  ];

  render() {
    // Image view collections
    let ImageViewCollections = this.types.map((type, index) => {
      return (
        <ImageView
          key={index}
          type={type}
          text={this.typeLabels[index]}
          imagePreviewUrl={this.props[type + "ImagePreviewUrl"]}
          defaultImagePreviewUrl={this.defaultTypeImages[index]}
          from={"UserCard"}
        ></ImageView>
      );
    });

    // Next button enable status
    let uploadImages = [];
    for (let type of this.types)
      if (!!this.props[type + "ImagePreviewUrl"])
        uploadImages.push(this.props[type + "ImagePreviewUrl"]);
    let nextButtonDisableStatus = !(uploadImages.length === this.types.length);

    return (
      <Card>
        {/* อัพโหลดรูปภาพ */}
        <div className="text-center my-2">
          <span className="font-bold text-2xl">อัพโหลดรูปภาพของคุณ</span>
        </div>
        {/* กรอบรูปภาพ / อัพโหลดรูปภาพ */}
        <div className="pt-4">
          <span className="text-gray-600">รูปภาพของคุณ</span>
          <hr className="my-2"></hr>
          <div className="flex flex-wrap items-center">
            {ImageViewCollections}
          </div>

          <div className="text-left">
            <span className="text-danger text-sm">
              *กรณีถ่ายรูปหันซ้าย-ขวา-เงย-ก้มหน้าให้อยู่ในมุมที่เห็นใบหน้าของท่านได้ชัดเจน
              ไม่หันมากจนเกินไป
            </span>
          </div>
        </div>
        {/* ปุ่มก่อนหน้า / ถัดไป */}
        <div className="flex justify-center mt-4">
          <Button
            className="w-3/12 btn-main"
            color={window.profileConfig.themeColor}
            onClick={this.props.backButtonClicked}
            outline
          >
            ก่อนหน้า
          </Button>
          <Button
            className="w-3/12 ml-4 btn-main"
            color={window.profileConfig.themeColor}
            onClick={this.props.nextButtonClicked}
            disabled={nextButtonDisableStatus}
          >
            ถัดไป
          </Button>
        </div>
      </Card>
    );
  }
}

export default UserCard;
