import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import './styles/main.css';
import './styles/register-theme.css';

import MemberPage from './pages/Member/Member';
import IdCardPage from './pages/Idcard/Idcard';
import UserPage from './pages/User/User';
import UserUploadPage from './pages/UserUpload/UserUpload';
import ConfirmPage from './pages/Confirm/Confirm';
import UpdatePage from './pages/Update/Update';
import UpdateUploadPage from './pages/UpdateUpload/UpdateUpload';

import wallpaper from './images/wallpaper_swap.svg';

class App extends Component {

  openManual() {
    window.open('manual.pdf');
  }

  getPagePermission = (type) => {
    let allow = false;
    switch(type) {
      case 'IDCARD':
        // Member form should be filled
        if(this.props.member) allow = true;
        break;
      case 'USER':
        // IdCard form should be filled
        if(this.props.idcard) allow = true;
        break;
      case 'CONFIRM':
        // Every form should be filled
        if(
            this.props.member &&
            this.props.idcard &&
            this.props.front &&
            this.props.left &&
            this.props.right &&
            this.props.below &&
            this.props.above
          ) {
            allow = true;
        }
        break;
      default:
        break;
    }
    return allow;
  }

  render() {
    let idCardPagePermission = this.getPagePermission('IDCARD');
    let userPagePermission = this.getPagePermission('USER');
    let confirmPagePermission = this.getPagePermission('CONFIRM');

    let backgroundStyles = {
      backgroundImage: `url(${wallpaper})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed'
      
    }

    return (
      <Router>
        <div className='h-screen background-register' style={{...window.profileConfig.style}}>
          <div className='background-register' style={{...backgroundStyles, ...window.profileConfig.style}}>
            <div className='flex justify-end p-2'>
              <Button onClick={this.openManual} color={window.profileConfig.themeColor}>คู่มือการลงทะเบียน</Button>
            </div>
            <Switch>
              <Route path='/idcard'>
                {idCardPagePermission ? <IdCardPage /> : <Redirect to='/'/>}
              </Route>
              <Route path='/user-reupload/:type'>
                <UpdateUploadPage />
              </Route>
              <Route path='/user-reupload'>
                <UpdatePage />
              </Route>
              <Route path='/user/:type'>
                {userPagePermission ? <UserUploadPage /> : <Redirect to='/idcard'/>}
              </Route>
              <Route path='/user'>
                {userPagePermission ? <UserPage /> : <Redirect to='/idcard'/>}
              </Route>
              <Route path='/confirm'>
                {confirmPagePermission ? <ConfirmPage /> : <Redirect to='/user'/>}
              </Route>
              <Route path='/' exact>
                <MemberPage></MemberPage>
              </Route>
              <Route path='*'>
                <Redirect to='/'/>
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    );
  }

}

const mapStateToProps = state => {
  return {
    member: state.member.member,
    idcard: state.idcard.idcard,
    front: state.user.front,
    left: state.user.left,
    right: state.user.right,
    below: state.user.below,
    above: state.user.above
  };
}

export default connect(mapStateToProps, null)(App);
