import * as actionTypes from '../actions/actionTypes';

const initialState = {
  member: null
};

const setMember = ( state, member ) => {
  const updatedState = {
    ...state,
    member: member
  };
  return updatedState;
};


const clearMember = ( state, action ) => {

};


const reducer = ( state = initialState, action ) => {
  switch( action.type ) {
    case actionTypes.SET_MEMBER:
      return setMember(state, action.member);
    case actionTypes.CLEAR_MEMBER:
      return clearMember();
    default:
      return state;
  }
};

export default reducer;