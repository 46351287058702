import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../store/actions/index';
import MainLayout from '../../layouts/MainLayout';
import UpdateCard from '../../components/UpdateCard/UpdateCard';
import api from '../../services/api';

class Update extends Component {
  state = {
    front: null,
    frontImagePreviewUrl: null,
    left: null,
    leftImagePreviewUrl: null,
    right: null,
    rightImagePreviewUrl: null,
    below: null,
    belowImagePreviewUrl: null,
    above: null,
    aboveImagePreviewUrl: null
  }

  componentDidMount() {
    let types = ['front', 'left', 'right', 'below', 'above'];
    for(let type of types) {
      this.setState({
        [type]: this.props[type],
        [type + 'ImagePreviewUrl']: this.props[type + 'ImagePreviewUrl']
      });
    }
  }

  fileSelectedHandler = (event, type) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
        this.setState({ [type]: file, [type + 'ImagePreviewUrl']: reader.result});
        this.props.onSetUpdate({ type: type, data: file, imagePreviewUrl: reader.result });
    }
    reader.readAsDataURL(file);
  }

  nextButtonClicked = () => {
    this.uploadImages();
  }

  uploadImages = async () => {
    try {
      const queryString = require('query-string');
      const parsed = queryString.parse(this.props.location.search);
      const formData = new FormData();
      formData.append('front', this.state.front);
      formData.append('left', this.state.left);
      formData.append('right', this.state.right);
      formData.append('down', this.state.below);
      formData.append('up', this.state.above);
      api.defaults.headers.common['Content-Type'] = `multipart/form-data; boundary=${formData._boundary}`;
      api.defaults.headers.common['Authorization'] = `Bearer ${parsed.token}`;
      await api.post(`/v1/faceverify/reUploadFaceVerifyPhoto`, formData);
      window.location.href = window.profileConfig.destinationLink;
    } catch(err) {
      console.log('Upload image error!', err);
      console.log(err.response);
    }
  }

  render() {
    return (
      <MainLayout>
        <UpdateCard
          frontImagePreviewUrl={this.state.frontImagePreviewUrl}
          leftImagePreviewUrl={this.state.leftImagePreviewUrl}
          rightImagePreviewUrl={this.state.rightImagePreviewUrl}
          belowImagePreviewUrl={this.state.belowImagePreviewUrl}
          aboveImagePreviewUrl={this.state.aboveImagePreviewUrl}
          fileSelectedHandler={this.fileSelectedHandler}
          nextButtonClicked={this.nextButtonClicked}
        ></UpdateCard>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  let types = ['front', 'left', 'right', 'below', 'above'];
  let propsState = {};
  for(let type of types) {
    propsState[type] = state.user[type];
    propsState[type + 'ImagePreviewUrl'] = state.user[type + 'ImagePreviewUrl'];
  }
  return {...propsState}
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetUser: (image) => dispatch(actions.setUser(image))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Update));