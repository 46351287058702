import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8008/api'

const client = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json'
  },
})

// Company Profile config
let domain = window.location.hostname;
domain = domain.split('.')[1];
try {
  require (`../profiles/${domain}/profile.js`);
} catch(err) {
  require (`../profiles/default/profile.js`);
}

client.defaults.headers.common['ComId'] = window.profileConfig.comId;
client.defaults.headers.common['Content-Type'] = 'application/json';

// const token = process.env.REACT_APP_TOKEN;
// client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
const key = process.env.REACT_SECRET_KEY || '8mLAFCIxYZafFsVHeIsrLKJVM4Pzlt7SwHdolkjRISJfs9BDKSgd7Q6q2YQc';
client.defaults.headers.common['key'] = key;

export default client