import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import MainLayout from '../../layouts/MainLayout';
import MemberCard from '../../components/MemberCard/MemberCard';
import * as actions from '../../store/actions/index';
import service from '../../services/api';

class Member extends Component {

  state = {
    prefixName: '',
    prefixNameError: '',
    firstName: '',
    firstNameError: '',
    lastName: '',
    lastNameError: '',
    idcardNumber: '',
    idcardNumberError: '',
    email: '',
    emailError: '',
    phoneNumber: '',
    phoneNumberError: '',
    password: '',
    passwordError: '',
    passwordConfirm: '',
    passwordConfirmError: '',
    usedEmail: ''
  }

  componentDidMount = () => {
    if (this.props.member)
      this.restoreMember(this.props.member);
  }

  inputHandler = (attr, value) => {
    if (attr == 'email' && value.match("^[a-zA-Z0-9.@_-]*$") != null) {
      this.setState({ email: value });
    }

    else if (attr == 'phoneNumber' && value.match("^[0-9]{0,10}$") != null) {
      this.setState({ phoneNumber: value });
    }
    else if (attr == 'idcardNumber' && value.match("^[0-9]{0,13}$") != null) {
      this.setState({ idcardNumber: value });
    }
    else if (attr == 'prefixName' && value.match("^[ก-๏]*$") != null) {
      this.setState({ prefixName: value });
    }
    else if (attr == 'firstName' && value.match("^[ก-๏]*$") != null) {
      this.setState({ firstName: value });
    }
    else if (attr == 'lastName' && value.match("^[ก-๏]*$") != null) {
      this.setState({ lastName: value });
    }
    else if (attr == 'password' || attr == 'passwordConfirm') {
      this.setState({ [attr]: value });
    }
  }

  fetchAlreadyUsedEmails = async () => {
    if (this.state.email == "") {
      this.state.usedEmail = false;
    }
    else {
      let result = await service.get(`/v1/email/` + this.state.email + `/company/` + window.profileConfig.comId);
      console.log(result);
      this.state.usedEmail = result.data;
    }
  }

  nextButtonClicked = async () => {
    // Fetch the already use emails
    let registeredEmails = await this.fetchAlreadyUsedEmails();
    // Validate the form
    let hasError = this.validateTheForm([
      'prefixName', 'firstName', 'lastName', 'idcardNumber',
      'email', 'phoneNumber', 'password', 'passwordConfirm'
    ], registeredEmails);
    if (hasError) return;
    // Save the form into redux
    const {
      prefixName, firstName, lastName, idcardNumber,
      email, phoneNumber, password
    } = this.state;
    const member = {
      prefixName: prefixName,
      firstName: firstName,
      lastName: lastName,
      idcardNumber: idcardNumber,
      email: email,
      phoneNumber: phoneNumber,
      password: password,
    };
    this.props.onSetMember(member);
    this.props.history.push('/idcard');
  }

  validateTheForm = (keyArrays) => {
    let foundError = false;
    for (let key of keyArrays) {
      let currentText = this.state[key];
      /** Common rule */
      if (currentText.trim().length <= 0) {
        this.setState({ [key + 'Error']: 'จำเป็นต้องกรอกข้อมูล' });
        foundError = true;
        continue;
      }
      /** Specific rule */
      // prefix rule
      if (['prefixName'].includes(key)) {
        if (currentText.trim().length < 1) {
          this.setState({ [key + 'Error']: "ต้องการอย่างน้อย 1 ตัวอักษร" });
          foundError = true;
          continue;
        }
      }
      // name rule
      if (['firstName', 'lastName'].includes(key)) {
        if (/\d/.test(currentText)) {
          this.setState({ [key + 'Error']: "ข้อมูลไม่ถูกต้อง" });
          foundError = true;
          continue;
        }
        if (currentText.trim().length < 2) {
          this.setState({ [key + 'Error']: "ต้องการอย่างน้อย 2 ตัวอักษร" });
          foundError = true;
          continue;
        }
      }
      // idcard rule
      if (['idcardNumber'].includes(key)) {
        if (!(/^[0-9]+$/).test(currentText.trim()) || currentText.trim().length !== 13) {
          this.setState({ [key + 'Error']: "ต้องการตัวเลข 13 หลัก (ไม่ต้องใส่เครื่องหมาย - )" });
          foundError = true;
          continue;
        }
      }
      // email rule
      if (['email'].includes(key)) {
        if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(currentText)) {
          this.setState({ [key + 'Error']: "โปรดกรอกอีเมลให้ถูกต้อง" });
          foundError = true;
          continue;
        }
        if (this.state.usedEmail == true) {
          this.setState({ [key + 'Error']: "อีเมลนี้ถูกใช้งานแล้ว กรุณาใช้อีเมลอื่น" });
          foundError = true;
          continue;
        }
        /*if (registeredEmails.includes(currentText)) {
          this.setState({ [key + 'Error']: "อีเมลนี้ถูกใช้งานแล้ว กรุณาใช้อีเมลอื่น" });
          foundError = true;
          continue;
        }*/
      }
      // phoneNumber rule
      if (['phoneNumber'].includes(key)) {
        if (!(/^[0-9]+$/).test(currentText.trim()) || currentText.trim().length !== 10) {
          this.setState({ [key + 'Error']: "ต้องการตัวเลข 10 หลัก (ไม่ต้องใส่เครื่องหมาย - )" });
          foundError = true;
          continue;
        }
      }
      // password rule
      if (['password', 'passwordConfirm'].includes(key)) {
        const errortxt = "";
        //at least 8 characters
        if (currentText.trim().length < 8) {
          this.setState({ [key + 'Error']: "ต้องการอย่างน้อย 8 ตัวอักษร" });
          foundError = true;
          continue
        }
        //at least 1 number
        if (!currentText.trim().match("(?=.*[a-z])(?=.*[A-Z])")) {
          this.setState({ [key + 'Error']: " ต้องมีตัวอักษรภาษาอังกฤษ ทั้งพิมพ์ใหญ่และพิมพ์เล็กอย่างน้อย 1 ตัว" });
          foundError = true;
          continue
        }
        //at least 1 capital letter & 1 lowercase letter 
        if (!currentText.trim().match("(?=.*[0-9])")) {
          this.setState({ [key + 'Error']: " ต้องมีตัวเลขอย่างน้อย 1 ตัว" });
          foundError = true;
          continue
        }
        else {
          this.setState({ [key + 'Error']: "" });
        }
        if (this.state.password !== this.state.passwordConfirm) {
          this.setState({ ['passwordConfirm' + 'Error']: "รหัสผ่าน และ ยืนยันรหัสผ่าน ไม่ตรงกัน" });
          foundError = true;
          continue;
        }
      }
      this.setState({ [key + 'Error']: "" });
    }
    return foundError;
  }

  restoreMember = (member) => {
    this.setState({
      prefixName: member.prefixName,
      firstName: member.firstName,
      lastName: member.lastName,
      idcardNumber: member.idcardNumber,
      email: member.email,
      phoneNumber: member.phoneNumber,
      password: member.password,
      passwordConfirm: member.password
    });
  }

  render() {
    let {
      prefixName, prefixNameError,
      firstName, firstNameError,
      lastName, lastNameError,
      idcardNumber, idcardNumberError,
      email, emailError,
      phoneNumber, phoneNumberError,
      password, passwordError,
      passwordConfirm, passwordConfirmError, policy, agreeColor
    } = this.state;

    return (
      <MainLayout>
        <MemberCard
          prefixName={prefixName}
          prefixNameError={prefixNameError}
          firstName={firstName}
          firstNameError={firstNameError}
          lastName={lastName}
          lastNameError={lastNameError}
          idcardNumber={idcardNumber}
          idcardNumberError={idcardNumberError}
          email={email}
          emailError={emailError}
          phoneNumber={phoneNumber}
          phoneNumberError={phoneNumberError}
          password={password}
          passwordError={passwordError}
          passwordConfirm={passwordConfirm}
          passwordConfirmError={passwordConfirmError}
          input={this.inputHandler}
          next={this.nextButtonClicked}
        ></MemberCard>
      </MainLayout>
    );
  }

}

const mapStateToProps = state => {
  return {
    member: state.member.member
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onSetMember: (member) => dispatch(actions.setMember(member))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Member));