import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import css from './IdCardCard.module.css';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Card from '../Card/Card';
import IdCardExampleImage from '../../images/idcard-example.png';

class IdCardCard extends Component {

  render() {
    return (
      <Card>
        {/* อัพโหลดรูปภาพประชาชน */}
        <div className='text-center my-2'>
          <span className='font-bold text-2xl title-imageview'>อัพโหลดรูปภาพบัตรประชาชน</span>
        </div>
        {/* รูปภาพตัวอย่าง */}
        <div className='mt-4'>
          <div className='flex justify-center items-center'>
              <img 
                className='object-cover border border-black p-1'
                src={this.props.idcardImagePreviewUrl ? this.props.idcardImagePreviewUrl : IdCardExampleImage} 
                alt='idcard-example' 
                style={{'width': '300px', 'height': '150px'}}
              />
          </div>
          <div className='text-center mt-2'>
            <span className='text-gray-400'>{this.props.idcard ? 'รูปภาพบัตรประชาชน' : 'ตัวอย่าง'}</span>
          </div>
        </div>
        {/* อัพโหลดไฟล์ */}
        <div className='mt-4'>
          <div className='flex'>
            <span>อัพโหลดรูปภาพบัตรประชาชนจากเครื่อง</span>
            {this.props.idcardImagePreviewUrl ? <div className='ml-2'>
              <FontAwesomeIcon icon={faCheck} className={css.icon}></FontAwesomeIcon>
            </div> : null}
          </div>
          <div className='mt-2'>
            <Input 
              type="file" 
              name="file" 
              id="idCardFile"
              onChange={this.props.fileSelectedHandler}
            />
          </div>
        </div>
        {/* ปุ่มก่อนหน้า / ปุ่มถัดไป */}
        <div className='flex justify-center items-center mt-5'>
            <Button 
              className='w-3/12 btn-main' 
              color={window.profileConfig.themeColor} 
              onClick={this.props.backButtonClicked}
              outline
            >ก่อนหน้า</Button>
            <Button 
              className='w-3/12 ml-4 btn-main'
              color={window.profileConfig.themeColor} 
              onClick={this.props.nextButtonClicked}
              disabled={!this.props.idcardImagePreviewUrl}
            >ถัดไป</Button>
        </div>
      </Card>
    );
  }

}

export default IdCardCard;