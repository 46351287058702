const bgImage = 'https://www.better.coursesquare.co/images/better/banner/better_banner_dk.png'
const website = 'https://www.trainingplus.coursesquare.co'

window.profileConfig = {
  companyName: 'สมาคมนายหน้าประกันภัย',
  destinationLink: website,
  backgroundImage: bgImage,
  themeColor: 'primary',
  comId: 470,
  style: {
    // Ex. color: 'red'
  }
}