import React, { Component } from 'react';
import '../styles/confirm-alert.css';
class ConfirmAlertModal extends Component {
    agreeButtonClicked = async () => {
        document.getElementById('confirm-alert-card').style.display = "none";
        window.location.href = window.profileConfig.destinationLink;
    }
    render() {
        return (
            <div class="confirm-alert" id="confirm-alert-card">
            <div class="modal-content">
              <div class="modal-header">
                <p class="modal-text-header"> การสมัครสมาชิกเสร็จสมบูรณ์</p>
              </div>
              <div class="modal-body">
                <p>ผู้ดูแลระบบจะตรวจสอบข้อมูลของท่าน <br/>โดยท่านจะได้รับอีเมลยืนยันการสมัครสมาชิก ภายใน 3 วันทำการ</p>
              </div>
              <div class="modal-footer">
                <button class="accept-btn" onClick={() => this.agreeButtonClicked()}>ตกลง</button>
              </div>
            </div>
          </div>
        );
    }
}
export default ConfirmAlertModal;