const bgImage = 'https://i0.wp.com/www.quickpconline.com/pc/wp-content/uploads/2020/04/cover-csq-sely.jpg?resize=1024%2C683'
const website = 'https://www.trtnonline.com'

window.profileConfig = {
  companyName: 'บริษัท ไทยรี เซอร์วิสเซส จํากัด',
  destinationLink: website,
  backgroundImage: bgImage,
  themeColor: 'warning',
  comId: 503,
  style: {
    // Ex. color: 'red'
  }
}