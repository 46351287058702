import React, { Component } from 'react';
import '../styles/webcam-alert.css';
class WebcamAlertModal extends Component {
  
  backButtonClicked = async () => {
    window.location.href = '/';
}
    render() {
        return (
            <div class="webcam-alert" >
            <div class="web-content">
              <div class="web-header">
                <p class="modal-text-header"> ระบบไม่รองรับ Web Browser ที่ใช้อยู่</p>
              </div>
              <div class="web-body">
                <p>กรุณาเข้าระบบใหม่โดยใช้<br/>Google Chrome, FireFox, Safari หรือ Microsoft Edge</p>
              </div>
              <div class="modal-footer">
                <button class="accept-web-btn" onClick={() => this.backButtonClicked()}>ย้อนกลับ</button>
              </div>
            </div>
          </div>
        );
    }
}
export default WebcamAlertModal;