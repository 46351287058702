import React, { Component } from 'react';

class MainLayout extends Component {

  render() {
    let divStyle = [  
      'flex justify-center',
      'p-4'
    ];

    return (
      <div className={divStyle.join(' ')}>
        {this.props.children}
      </div>
    );
  }

}

export default MainLayout;