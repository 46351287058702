import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import css from './ImageView.module.css';

class ImageView extends Component {

  linkToPage = (type) => {
    switch (this.props.from) {
      case "UserCard":
        this.props.history.push(`/user/${type}`);
        break;
      case "UpdateCard":
        this.props.history.push(`/user-reupload/${type}`);
        break;
      default:
        break;
    }
  }

  render() {
    let cardContainerCss = ['flex justify-center items-center justify-content-sm-center h-100', css['card-container']];
    let imageNotUploadOverlayCss = ['flex justify-center items-center', css['overlay-not-upload']];
    let imageUploadOverlayCss = [css['overlay-upload']];

    return (
      <div className={css['image-view']}>
        {/* แสดงรูปภาพ */}
        <div className={cardContainerCss.join(' ')}>
          {
            this.props.imagePreviewUrl ? 
            <img 
              className='justify-center object-cover border border-black css["image"]' 
              src={this.props.imagePreviewUrl}
              style={{'height': '200px', 'width': "100%"}}
              alt=''
            /> : 
            <img 
              className='justify-center object-cover border border-black css["image"]' 
              src={this.props.defaultImagePreviewUrl}
              style={{'height': '200px', 'width': "100%"}}
              alt=''
            />
          }
          {
            !this.props.imagePreviewUrl ? 
            <div className={imageNotUploadOverlayCss.join(' ')} onClick={() => this.linkToPage(this.props.type)}>
              คลิกเพื่อถ่ายภาพ
            </div> :
            <div className={imageUploadOverlayCss.join(' ')} onClick={() => this.linkToPage(this.props.type)}>
              คลิกเพื่อแก้ไข
            </div>
          }
        </div>
        {/* ข้อความ */}
        
        <div className='text-center mt-2'>
          <span>{this.props.text}</span>
        </div>
     </div>
    );
  }

}

export default withRouter(ImageView);