import React, { Component } from 'react';
// import '../ConfirmView/ConfirmView.css'

class ConfirmView extends Component {

  render() {
    return (
      <div className={['p-2', this.props.text.includes('บัตรประชาชน') ? '' : 'image-view'].join(' ')}>
        <div className='flex justify-center'>
          {
            this.props.imagePreviewUrl ? 
            <img 
              className='justify-center object-cover border border-black image' 
              src={this.props.imagePreviewUrl} 
              style={this.props.style}
              alt=''
            /> :
            <img 
              className='justify-center object-cover border border-black image' 
              src={this.props.defaultImagePreviewUrl} 
              style={this.props.style}
              alt=''
            />
          }
        </div>
        <div className='text-center mt-2'>
          <span>{this.props.text}</span>
        </div>
      </div>
    );
  }

}

export default ConfirmView;