import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import MainLayout from '../../layouts/MainLayout';
import ConfirmCard from '../../components/ConfirmCard/ConfirmCard';
import api from '../../services/api';
import '../../styles/loader.css';

class Confirm extends Component {

  state = {
    idcard: null,
    idcardImagePreviewUrl: null,
    front: null,
    frontImagePreviewUrl: null,
    left: null,
    leftImagePreviewUrl: null,
    right: null,
    rightImagePreviewUrl: null,
    below: null,
    belowImagePreviewUrl: null,
    above: null,
    aboveImagePreviewUrl: null,
    errorMessages: []
  }

  componentDidMount() {
    // idcard
    this.setState({
      idcard: this.props.idcard,
      idcardImagePreviewUrl: this.props.idcardImagePreviewUrl
    });
    // user
    let types = ['front', 'left', 'right', 'below', 'above'];
    for (let type of types) {
      this.setState({
        [type]: this.props[type],
        [type + 'ImagePreviewUrl']: this.props[type + 'ImagePreviewUrl']
      });
    }
  }

  backButtonClicked = () => {
    this.props.history.push('/user');
  }

  confirmButtonClicked = () => {
    document.getElementById("loading").style.display = "flex";
    this.createNewMember();
  }

  createNewMember = async () => {
    try {
      const newMemberObject = {
        "first_name": `${this.props.prefixName} ${this.props.firstName}`,
        "last_name": this.props.lastName,
        "id_card": this.props.idcardNumber,
        "email": this.props.email,
        "username": this.props.email,
        "password": this.props.password,
        "phone_number": this.props.phoneNumber,
        "company_id": window.profileConfig.comId
      }
      const res = await api.post('/v1/selfreg', newMemberObject);
      document.getElementById("loading").style.display = "none";
      this.setErrorMessages(null);
      this.uploadImages(res.data.data);
    } catch (err) {
      document.getElementById("loading").style.display = "none";
      console.log('createNewMember error!', err);
      console.log(err.response);
      this.setErrorMessages(err);
    }
  }

  uploadImages = async (member) => {
    try {
      const formData = new FormData();
      formData.append('idcard', this.state.idcard);
      formData.append('front', this.state.front);
      formData.append('left', this.state.left);
      formData.append('right', this.state.right);
      formData.append('down', this.state.below);
      formData.append('up', this.state.above);
      api.defaults.headers.common['Content-Type'] = `multipart/form-data; boundary=${formData._boundary}`;
      await api.post(`/v1/members/${member.id}/faceverify`, formData);
      this.setErrorMessages(null);
      document.getElementById('confirm-alert').style.display = "block";
    } catch (err) {
      console.log('Upload image error!', err);
      console.log(err.response);
      this.setErrorMessages(err);
    }
  }

  setErrorMessages = (err) => {
    if (err) {
      let updatedErrorMessages = [];
      for (let key in err.response.data.errors)
        updatedErrorMessages.push(err.response.data.errors[key][0]);
      this.setState({ errorMessages: updatedErrorMessages });
    } else {
      this.setState({ errorMessages: [] });
    }

  }

  render() {
    return (
      <MainLayout>
        <ConfirmCard
          prefixName={this.props.prefixName}
          firstName={this.props.firstName}
          lastName={this.props.lastName}
          idcardNumber={this.props.idcardNumber}
          email={this.props.email}
          phoneNumber={this.props.phoneNumber}
          password={this.props.password}
          idcardImagePreviewUrl={this.state.idcardImagePreviewUrl}
          frontImagePreviewUrl={this.state.frontImagePreviewUrl}
          leftImagePreviewUrl={this.state.leftImagePreviewUrl}
          rightImagePreviewUrl={this.state.rightImagePreviewUrl}
          belowImagePreviewUrl={this.state.belowImagePreviewUrl}
          aboveImagePreviewUrl={this.state.aboveImagePreviewUrl}
          backButtonClicked={this.backButtonClicked}
          confirmButtonClicked={this.confirmButtonClicked}
          errorMessages={this.state.errorMessages}
        ></ConfirmCard>
        <div id="loading" 
              style={{ width: "100%",
                      height: "100%", 
                      backgroundColor: "black", 
                      position: "fixed", 
                      opacity: "0.5", 
                      top:"0",
                      display: "none",
                      alignItems: "center",
                      justifyContent: "center"
                }}>
          <div class="loader"></div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = (state) => {
  // member info
  let propsStateMemberInfo = state.member.member;
  // idcard image
  let propsStateIdcard = {
    idcard: state.idcard.idcard,
    idcardImagePreviewUrl: state.idcard.idcardImagePreviewUrl
  };
  // user image
  let types = ['front', 'left', 'right', 'below', 'above'];
  let propsStateUser = {};
  for (let type of types) {
    propsStateUser[type] = state.user[type];
    propsStateUser[type + 'ImagePreviewUrl'] = state.user[type + 'ImagePreviewUrl'];
  }
  return { ...propsStateUser, ...propsStateIdcard, ...propsStateMemberInfo }
}

export default connect(mapStateToProps, null)(withRouter(Confirm));
