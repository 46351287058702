const bgImage = 'https://i0.wp.com/www.quickpconline.com/pc/wp-content/uploads/2020/04/cover-csq-sely.jpg?resize=1024%2C683'
const website = 'https://www.google.co.th'

window.profileConfig = {
  companyName: 'บริษัท คอร์สสแควร์ จำกัด',
  destinationLink: website,
  backgroundImage: bgImage,
  themeColor: 'warning',
  comId: 470,
  style: {
    // Ex. color: 'red'
  }
}