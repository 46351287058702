const bgImage = 'https://i0.wp.com/www.quickpconline.com/pc/wp-content/uploads/2020/04/cover-csq-sely.jpg?resize=1024%2C683'
const website = 'https://www.tqmlearning.com/'

window.profileConfig = {
  companyName: 'บริษัท ทีคิวเอ็ม อินชัวร์รันส์ โบรคเกอร์ จำกัด',
  destinationLink: website,
  backgroundImage: bgImage,
  themeColor: 'warning',
  comId: 506,
  style: {
    // Ex. color: 'red'
  }
}