import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import * as dotenv from 'dotenv';
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import memberReducer from './store/reducers/member';
import idcardReducer from './store/reducers/idcard';
import userReducer from './store/reducers/user';

// Company Profile config
let domain = window.location.hostname;
domain = domain.split('.')[1];
try {
  require (`./profiles/${domain}/profile.js`);
} catch(err) {
  require (`./profiles/default/profile.js`);
}

dotenv.config();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  member: memberReducer,
  idcard: idcardReducer,
  user: userReducer
});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
