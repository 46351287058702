const bgImage = 'https://www.better.coursesquare.co/images/better/banner/better_banner_dk.png'
const website = 'https://www.tfac.coursesquare.co'

window.profileConfig = {
  companyName: 'สภาวิชาชีพบัญชี ในพระบรมราชูปถัมภ์',
  destinationLink: website,
  backgroundImage: bgImage,
  themeColor: 'primary',
  comId: 485,
  style: {
    // Ex. color: 'red'
  }
}