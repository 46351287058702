import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Card from '../Card/Card';
import ConfirmView from '../ConfirmView/ConfirmView';
import IdcardDefaultImage from '../../images/idcard-example.png';
import FrontDefaultImage from '../../images/front-example.png';
import LeftDefaultImage from '../../images/left-example.png';
import RightDefaultImage from '../../images/right-example.png';
import UpDefaultImage from '../../images/up-example.png';
import DownDefaultImage from '../../images/down-example.png';
import ConfirmAlertModal from '../ConfirmAlertModal';
import '../ConfirmCard/ConfirmCard.css';
class ConfirmCard extends Component {

  types = ['front', 'left', 'right', 'below', 'above'];
  typeLabels = ['รูปภาพหน้าตรง', 'รูปภาพหน้าหันซ้าย', 'รูปภาพหน้าหันขวา', 'รูปภาพหน้ามุมก้ม', 'รูปภาพหน้ามุมเงย'];
  defaultTypeImages = [FrontDefaultImage, LeftDefaultImage, RightDefaultImage, UpDefaultImage, DownDefaultImage];

  render() {
    let IdCardConfirmViewCollections = [
      <ConfirmView
        key='0'
        text='รูปภาพบัตรประชาชน'
        imagePreviewUrl={this.props.idcardImagePreviewUrl}
        defaultImagePreviewUrl={IdcardDefaultImage}
        style={{ 'width': '300px', 'height': '150px' }}
      ></ConfirmView>,
      <div id="confirm-alert" style={{display: "none"}}><ConfirmAlertModal /></div>
      
    ];

    let UserConfirmViewCollections = this.types.map((type, index) => {
      return (
          <ConfirmView
            key={index}
            text={this.typeLabels[index]}
            imagePreviewUrl={this.props[type + 'ImagePreviewUrl']}
            defaultImagePreviewUrl={this.defaultTypeImages[index]}
            style={{ 'width': '100%', 'height': '200px' }}
          ></ConfirmView>
      );
    });

    const formCase = (
      !!this.props.prefixName &&
      !!this.props.firstName &&
      !!this.props.lastName &&
      !!this.props.idcardNumber &&
      !!this.props.email &&
      !!this.props.phoneNumber &&
      !!this.props.password
    );
    const imageCase = (
      !!this.props.frontImagePreviewUrl &&
      !!this.props.leftImagePreviewUrl &&
      !!this.props.rightImagePreviewUrl &&
      !!this.props.aboveImagePreviewUrl &&
      !!this.props.belowImagePreviewUrl
    );

    return (
      <Card>
        {/* ยืนยันรูปสำหรับสมัครสมาชิก */}
        <div className='text-center my-2'>
          <span className='font-medium text-2xl'>ยืนยันรูปสำหรับสมัครสมาชิก</span>
        </div>
        {/* รูปภาพ */}
        <div className='pt-4'>
          <span className='text-gray-600'>กรุณาตรวจสอบรูปภาพให้ถูกต้องก่อนยืนยันการสมัครสมาชิก</span>
          <hr className='my-2'></hr>
          <div className='flex justify-center'>
            {IdCardConfirmViewCollections}
          </div>
          <div className='flex flex-wrap'>
            {UserConfirmViewCollections}
          </div>
        </div>
        {/* ปุ่มก่อนหน้า / ยืนยัน */}
        <div className='flex justify-center mt-4'>
          <Button
            className='w-3/12 btn-main'
            color={window.profileConfig.themeColor}
            onClick={this.props.backButtonClicked}
            outline
          >ก่อนหน้า</Button>
          <Button
            className='w-3/12 ml-4 btn-main'
            color={window.profileConfig.themeColor}
            onClick={this.props.confirmButtonClicked}
            disabled={!(formCase && imageCase)}
          >ยืนยัน</Button>
        </div>
        {/* ข้อความแสดงเหตุผลที่สมัครไม่สำเร็จ */}
        {
          this.props.errorMessages.length > 0 ?
            <div className='text-center mt-4'>
              <p className='text-xl text-red-700 font-medium'>สมัครสมาชิกไม่สำเร็จ !!</p>
              {this.props.errorMessages.map(err => {
                return (<p className='text-sm mt-2 text-red-400'>{err}</p>)
              })}
            </div> : null
        }
      </Card>
    );
  }

}

export default ConfirmCard;